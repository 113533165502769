import '@/css/app.css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

import 'simple-lightbox/dist/simpleLightbox.min.css';

import { init as CookieInit } from 'cookie-though';
import { simpleLightbox, gsapBatchUpBase, swiperDefault, swiperGallery } from './components/defaults'

/*import {
    //stickyHeader,
    //gsapBatchLeftBase,
    //gsapBatchUpBase,
    swiperDefault,
    //simpleLightbox,
} from './components/tools';*/

import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'
Alpine.plugin([focus, collapse])

//import { mountVueApp } from '@/vue/app.ts';
import { mountReactApp } from '../react/app'; //'@/react/app.tsx';

const main = async () => {
    cookieThough()
    setTimeout(() => gsapBatchUpBase(), 300)
    swiperDefault()
    swiperGallery()
    simpleLightbox()

    // Vue / React
    //mountVueApp();
    mountReactApp()

    newsletter()

    const components = ['reactRental'];

    const promises = [];

    for (const comp of components) {
        const compElements = document.querySelectorAll(`.${comp}`);

        const { init } = await import(`./components/${comp}.ts`);

        compElements.forEach((comp) => {
            promises.push(init(comp));
        });
    }

    await Promise.all(promises).catch((e) => {
        console.error(e.message, e);
    });
};

const cookieThough = () => {
    setTimeout(() => {
        CookieInit(
            translations?.cookieThough
            /*{
            "policies": [
                {
                    "id": "essential",
                    "label": "Essential Cookies",
                    "description": "We need to save some technical cookies, for the website to function properly.",
                    "category": "essential",
                },
                {
                    "id": "functional",
                    "label": "Functional Cookies",
                    "category": "functional",
                    "description": "We need to save some basic preferences eg. language.",
                },
                {
                    "id": "statistics",
                    "label": "Statistics",
                    "category": "statistics",
                    "description": "We need to save some technical cookies, for the website to function properly.",
                },
                {
                    "id": "social",
                    "label": "Social Media Cookies",
                    "category": "social",
                    "description": "We need to save some social cookies, for the website to function properly.",
                },
            ],
            "essentialLabel": "Always on",
            "permissionLabels": {
                "accept": "Accept",
                "acceptAll": "Accept all",
                "decline": "Decline"
            },
            "cookiePreferenceKey": "cookie-preferences",
            "header": {
                "title": "cookie though?",
                "subTitle": "You're probably fed up with these banners...",
                "description": "Everybody wants to show his best side - and so do we. That’s why we use cookies to guarantee you a better experience."
            },
            "cookiePolicy": {
                "url":"https://inthepocket.com/cookie-policy",
                "label":"Read the full cookie declaration",
            },
            "customizeLabel": "Customize"
        }*/);
    }, 1000);

    /*document.getElementById("re-consent").addEventListener("click", () => {
      CookieThough.show();
    });*/
};

const newsletter = () => {
    const newsletterButton = document.querySelector('a[href="#newsletter"]');

    if (newsletterButton) {
        newsletterButton.addEventListener('click', triggerNewsletterOpen);
    }

    function triggerNewsletterOpen() {
        document.getElementById('newsletter-button').click();
    }
}

main().then(() => {
    console.info('Vite <3');
    Alpine.start();
});